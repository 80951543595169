import * as React from "react"
import MainLayout from "../components/layouts/MainLayout"
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from 'gatsby-background-image';

// Styled
import styled from 'styled-components';

// Utils
import { tablet } from "../assets/styles/Responsive";

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import NotFound from "../components/404/NotFound";

const PageWrapper = styled(BackgroundImage)`
  padding: 80px 0 110px 0px;
  background-size: cover;
  background-position: center;

    min-height: 60vh;

  @media (max-width: ${tablet}) {
    padding: 0 0 70px 0;
  }
`;

const NotFoundPage = () => {

    const {
        reviewsBackground,
        links
    } = NotFoundQuery();
    
      const imageData = getImage(reviewsBackground?.localFile?.childImageSharp?.gatsbyImageData)
      const bgimage = convertToBgImage(imageData);

    return(
        <MainLayout>
            <PageWrapper
                Tag="section" 
                {...bgimage}
            >
                <NotFound links={links}/>
            </PageWrapper>
        </MainLayout>
    );
}

export default NotFoundPage

const NotFoundQuery = () => {
    const data = useStaticQuery(
      graphql`
        query NotFoundQuery {
          strapiContent {
            reviewsBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            links {
                id
                link
                subtitle
                title
            }
          }
        }
      `
    );
  
    const {
      strapiContent,
    } = data;
  
    return strapiContent;
};