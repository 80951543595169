import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
// Components
import Container from '../../layouts/Container';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
// UI
import H2 from '../../UI/Titles/H2';
import Fonts from '../../../assets/styles/Fonts';
// Assets
import ArrowRightGreen from '../../../images/icons/arrow-right-green.svg';
import ArrowRightShadow from '../../../images/icons/arrow-right-shadow.svg';
// Utils
import { tablet } from "../../../assets/styles/Responsive";

const LinksRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 50px -10px 0;

  @media (max-width: ${tablet}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Subtitle = styled.div`
  position: relative;
  margin-top: 50px;
  display: inline-block;
  color: ${props => props.isbig ? Colors.shadowGreen : Colors.green};
  font-size: 24px;
  line-height: 26.4px;
  font-family: ${Fonts.IBMSemiBold};
  ${Typography.underlineLink};

  &:after {
    bottom: -4px;
    background-color: ${props => props.isbig ? Colors.shadowGreen : Colors.green};
  }

  svg {
    transition: transform .1s linear;
  }

  @media (max-width: ${tablet}) {
    font-size: 18px;
    line-height: 19.8px;
  }
`;

const LinkItem = styled(Link)`
  min-height: 340px;
  margin: ${props => props.single ? '0' : '0 10px'};
  padding: 28px 30px;
  width: calc(${props => props.single ? '100%' : props.isbig ? '60%' : '40%'} - 20px);
  text-decoration: none;
  background-color: ${props => props.isbig ? Colors.green : Colors.greenLight};
  transition: background-color .1s linear;

  &:hover {
    background-color: ${props => props.isbig ? Colors.mineralGreen : Colors.shadowGreen};

    svg {
      transform: translate(5px, 0);
    }

    ${Subtitle}:after, ${Subtitle}:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: ${tablet}) {
    min-height: auto;
    margin: 0 0 30px;
    width: 100%;
    padding: 25px 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Head1 = styled(H2)`
  color: ${props => props.isbig ? Colors.shadowGreen : Colors.green};
`;

const Links = ({ data, is404=false }) => {
  const single = data.length === 1;
  return (
    <Container>
      <LinksRow>
        {data.map((link, index) => {
          const { title, subtitle, link: path, id } = link;
          const isbig = (index % 2) === 0 ? true : false;
          return (
            <LinkItem to={`${path}`} isbig={isbig} key={id} single={single}>
              <Head1 value={title} isbig={isbig} />
              <Subtitle isbig={isbig}>{subtitle} {isbig ? <ArrowRightShadow/> : <ArrowRightGreen/>}</Subtitle>
            </LinkItem>
          );
        })}
      </LinksRow>
    </Container>
  );
};

Links.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Links;
