import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const Loader = () => {
return <>
            <Player 
                autoplay 
                loop 
                src="/loader-dots.json"
                style={{width: 200, height: 200}}
            />
        </>;
};

export default Loader;
