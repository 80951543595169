import {useQuery} from "@apollo/client"
import { GET_TOTAL_PRODUCT } from "../queries/query"
import useLocalStorage from "./useLocalStorage"

export const useGetTotalProduct = (options = {}) => {
	const [currentRetailer, setCurrentRetailer] = useLocalStorage('dutchie-plus--retailer-id')

	const variables = {
		retailerId: currentRetailer,
		productId: options.id
	}

	return useQuery(GET_TOTAL_PRODUCT, {
		variables,
		fetchPolicy: "cache-first"
	})
}