import React from 'react';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../styles/breakpoints';
import Links from '../templates/Links';
import { navigate } from "gatsby"


const Wrapper = styled.div`
    padding: 10vh 0 5vh 0;

    min-height: 60vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-family: Druk Wide Bold;
    }

    .maintitle{
        width: 35%;
    }

    .title, .maintitle{
        text-transform: uppercase;
        text-align: center;
        font-size: 36px;
    }

    .error{
        font-size: 100px;
    }

    @media(max-width: ${MOBILE_BREAKPOINT}){
        .maintitle{
            width: 85%;
        }

        .title{
            font-size: 20px;
        }

        .error{
            font-size: 60px;
        }
    }
`;

const Box = styled.div`
    margin-top: 5vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    border: 3px solid var(--darkgreen);
    border-radius: 25px;
    
    width: 35%;

    .header{
        display: flex;
        justify-content: flex-end;
        padding: 20px 30px;

        border-bottom: 3px solid var(--darkgreen);
        
        width: 100%;
    }

    @media(max-width: ${MOBILE_BREAKPOINT}){
        width: 85%;
    }
`;

const Dot = styled.div`
    border-radius: 12px;
    height: 25px;
    width: 25px;

    background: var(--darkgreen);

    margin-left: 10px;
`

const TextWrapper = styled.div`
    width: 75%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2vh 15px;

    p{
        margin: 2vh 0;
        text-align: center;
        font-weight: 600;
    }

    @media(max-width: ${MOBILE_BREAKPOINT}){
        p{
            font-size: 14px;
        }
    }
`

const LinkWrapper = styled.div`
    margin-top: 10vh;

    @media(max-width: ${MOBILE_BREAKPOINT}){
        margin-top: 3vh;
    }
`

export default function NotFound(props) {

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const id = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

  if(id && currentUrl.includes('menu')) {
    navigate(`/new-products/${id}`, {replace: true})
    return <></>
  }

    return(
        <Wrapper>
            <h1 className="maintitle">This isn’t the right place</h1>

            <Box>
                <div className="header">
                    <Dot></Dot>
                    <Dot></Dot>
                    <Dot></Dot>
                </div>

                <TextWrapper>
                    <h1 className="error">404</h1>

                    <h1 className="title">...whatever that means</h1>

                    <p>
                    Try adjusting your search or filter to find what you’re looking for. 
                    </p>
                </TextWrapper>
            </Box>

            <LinkWrapper>
                <Links data={props.links} is404={true}/>
            </LinkWrapper>
        </Wrapper>
    )
}