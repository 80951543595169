import React from "react"

import { useGetTotalProduct } from "../../hooks/useGetTotalProduct"
import TotalProductPage from "../../components/productpage/TotalProductPage"
import Loader from "../../components/menu/Others/Loader"
import NotFoundPage from "../404"
import styled from "styled-components"
import MainLayout from "../../components/layouts/MainLayout"

const ProductTemplate = (props) => {
	const id = props.id
	const { data, loading } = useGetTotalProduct({ id });
	const product = data?.product

	if (loading) return <BgLoader><Loader/></BgLoader>
	if (!product) {
		return <NotFoundPage />
	}

	return (
		<MainLayout>
			<TotalProductPage product={product}/>
		</MainLayout>
	)
}

export default ProductTemplate

const BgLoader = styled.div`
	background: #E3EFEA;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`
